import React from "react";
import _get from "lodash.get";

import { btnFields } from "./btn";
import BtnSignIn from "./btn-sign-in";

import SiteData from "../content/site-globals.json";
import ReactMarkdown from "react-markdown";

import * as S from "./footer.styles";

const FooterTitle = () => {
  const title = _get(SiteData, "footer.title");

  if (!title) {
    return null;
  }

  return (
    <S.Title>
      <ReactMarkdown
        components={{
          p: React.Fragment,
        }}
      >
        {title}
      </ReactMarkdown>
    </S.Title>
  );
};

const Footer = () => (
  <footer className="flex flex-col items-center justify-center bg-gray-dark py-16 px-gutter text-white sm:pt-44 sm:pb-20">
    <FooterTitle />
    <div className="flex max-w-6xl flex-col items-center justify-center gap-y-5 lg:flex-row">
      <p className="text-base leading-6 opacity-40">
        © {new Date().getFullYear()} {_get(SiteData, "footer.copyright")}
      </p>
      <S.List>
        {[..._get(SiteData, "footer.links")].map(
          ({ url, label, newWindow }, idx) => (
            <li key={idx}>
              <S.Link
                href={url}
                {...(newWindow
                  ? {
                      target: "_blank",
                      rel: "nofollow noopener",
                    }
                  : {})}
              >
                {label}
              </S.Link>
            </li>
          )
        )}
      </S.List>
      <BtnSignIn css={S.signIn} />
    </div>
  </footer>
);

export const footerFields = {
  label: "Footer",
  name: "footer",
  widget: "object",
  fields: [
    {
      label: "Title",
      name: "title",
      widget: "markdown",
      required: false,
    },
    {
      label: "Copyright",
      name: "copyright",
      widget: "string",
    },
    {
      label: "Links",
      name: "links",
      widget: "list",
      fields: btnFields.fields,
    },
  ],
};

export default Footer;
