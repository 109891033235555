import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { desktop } from "../styles/breakpoints";
import { colors, rem, fontSize, fontWeights } from "../styles/utils";
import { white } from "../styles/colors";

export const Title = styled.h2`
  margin-top: 0;
  margin-bottom: ${rem(60)};
  ${fontSize(26, 36)}
  font-weight: ${fontWeights.light};

  --max-width: 880px;
  max-width: var(--max-width);

  @media (${desktop}) {
    ${fontSize(40, 50)};
    margin-bottom: ${rem(160)};
  }

  a {
    border-bottom: 1px solid ${colors.white};
    padding-bottom: 0.1em;

    &:hover,
    &:focus {
      border-color: transparent;
    }
  }
`;

export const List = styled.ul`
  list-style: none;
  margin: 0 auto ${rem(32)};
  padding: 0;
  display: flex;
  flex-direction: column;

  @media (${desktop}) {
    flex-direction: row;
    align-items: center;
    margin: 0 ${rem(10)};
  }

  li {
    margin: ${rem(8)} auto;

    @media (${desktop}) {
      margin: 0;
      padding: 0 ${rem(8)};
    }
  }

  li + li {
    @media (${desktop}) {
      border-left: ${rem(1)} solid #474747;
    }
  }
`;

export const signIn = css`
  padding: ${rem(8)};
  min-width: ${rem(110)};
`;

export const Link = styled.a`
  display: block;
  padding: ${rem(7)};
  ${fontSize(14, 17)};
  font-weight: ${fontWeights.bold};
  text-transform: uppercase;
  color: ${white};
  text-decoration: none;

  &:hover {
    color: ${white};
    text-decoration: underline;
  }

  @media (${desktop}) {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const Content = styled.div`
  @media (${desktop}) {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: ${rem(1200)};
  }
`;

export const tedIcon = css`
  width: ${rem(82)};
  height: ${rem(28)};
`;
